import React from "react";
import "./ContactUs.scss";

const ContactUs = () => {
  return (
    <div
      className="bg-cover bg-center py-20 md:py-28 flex justify-center"
      style={{ backgroundImage: "url('/assets/images/home/contact_us.svg')" }}
    >
      <div className="w-full sm:w-3/4 md:w-2/4 px-4 md:px-0 text-center">
        <h3 className="text-white text-3xl md:text-4xl">Contact Us</h3>
        <p className="text-white text-lg md:text-2xl leading-7 md:leading-10 py-4">
          Ready to embark on your journey towards innovation and sustainability?
          Get in touch with us today!
        </p>
        <button className="bg-orange-500 text-white rounded-full bg-color text-lg md:text-xl px-8 md:px-12 py-2 md:py-3 mt-4 hover:bg-orange-600">
          Get in touch
        </button>
      </div>
    </div>
  );
};

export default ContactUs;

import React from "react";
import "./WhoAreWe.scss";
import OurServices from "../OurServices/OurServices";

const WhoAreWe =() =>{
    return(
        <>
            <div className="flex justify-center items-center px-4 lg:px-8 min-h-screen">
                <div className="max-w-7xl w-full flex flex-col lg:flex-row items-center lg:items-center p-8 lg:p-16 who-are-we-container">
                    <div className="lg:w-1/2 lg:pr-8 lg:text-start md:text-center sm:text-center">
                        <h2 className="text-orange-500 text-4xl lg:text-4xl font-bold mb-4">WHO ARE WE?</h2>
                        <p className="text-gray-700 text-base lg:text-lg">
                        We are a dynamic team of forward thinking technologists, we are dedicated to driving positive change through the power of technology. At Orrizonte, we believe in placing people at the heart of our mission, crafting solutions that are not only cutting-edge but also intuitive and adaptable to your unique needs.
                        </p>
                    </div>
                    <div className="lg:w-1/2 mt-8 lg:mt-0 flex justify-center lg:justify-end">
                        <img
                            src="/assets/images/whoAreWe/whoAreWe.svg" alt="Who-Are-We-Image"
                            className="w-full max-w-md"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhoAreWe;
import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
<footer className="relative bg-cover w-full bg-center mt-20">
        <div className="footer-width-container">
 <svg
  className="absolute inset-0 w-full h-full footer-dimensions"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  width="100%"
  height="100%"
  viewBox="0 0 1600 900"
  preserveAspectRatio="xMidYMax slice"
>
  <defs>
    <linearGradient id="bg">
    <stop offset="15%" style={{ stopColor: 'rgba(249, 130, 227, 0.06)' }}></stop>
                <stop offset="50%" style={{ stopColor: 'rgba(76, 190, 255, 0.6)' }}></stop>
                <stop offset="100%" style={{ stopColor: 'rgba(115, 209, 72, 0.2)' }}></stop>
    </linearGradient>
    <path
      id="wave"
      fill="url(#bg)"
      d="M-363.852,352.589c0,0,236.988-41.997,505.475,0 s371.981,38.998,575.971,0s293.985-39.278,505.474,5.859s493.475,48.368,716.963-4.995v360.106H-363.852V352.589z"
    />
  </defs>
  <g className="sm:transform translate-y-2">
    <use href="#wave" opacity="0.3">
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="translate"
        dur="10s"
        calcMode="spline"
        values="270 230; -334 180; 270 230"
        keyTimes="0; .5; 1"
        keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
        repeatCount="indefinite"
      />
    </use>
    <use href="#wave" opacity="0.6">
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="translate"
        dur="8s"
        calcMode="spline"
        values="-270 230;243 220;-270 230"
        keyTimes="0; .6; 1"
        keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
        repeatCount="indefinite"
      />
    </use>
    <use href="#wave" opacity="0.9">
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="translate"
        dur="6s"
        calcMode="spline"
        values="0 230;-140 200;0 230"
        keyTimes="0; .4; 1"
        keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
        repeatCount="indefinite"
      />
    </use>
  </g>
</svg>
</div>

      <div className=" flex justify-center items-center px-4 lg:px-8">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 flex flex-col md:flex-row justify-between items-center w-full lg:items-center p-8 lg:p-8 mt-10 sm:mt-20">
          <div className="mb-4 md:mb-0">
            <div className="orrizonte-logo flex items-center lg:justify-start md:jsutify-center sm:justify-center">
              <img
                src="/assets/images/home/orrizonteLogo.svg"
                alt="Orrizonte"
              />
            </div>
            <p className="text-start mt-10 leading-loose">
              Copyright © 2024 Orrizonte Technologies
            </p>
            <p className="all-rights text-start lg:text-start md:text-start sm:text-center">All rights reserved</p>
            <div className="social-container flex mt-10 lg:justify-start md:justify-start sm:justify-center">
              <img
                src="/assets/icons/instagram-icon.svg"
                alt="Orrizonte"
                className="me-4"
              />
              <img
                src="/assets/icons/twitter-icon.svg"
                alt="Orrizonte"
                className="me-4"
              />
              <img
                src="/assets/icons/youtube-icon.svg"
                alt="Orrizonte"
                className="me-4"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-12 text-center md:text-left">
            <div className="mb-4 md:mb-0 p-4 lg:p-8">
              <h4 className="font-bold mb-2">Important Links</h4>
              <ul className="leading-loose">
                <li>
                  <a href="/about" className="hover:underline">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="/contact" className="hover:underline">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="/terms-and-conditions" className="hover:underline">
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a href="/privacy-policy" className="hover:underline">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="p-4 lg:p-8">
              <h4 className="font-bold mb-2">Contact</h4>
              <address className="not-italic flex items-start leading-relaxed">
                <img
                  src="/assets/icons/map-pin-icon.svg"
                  alt="Orrizonte"
                  className="me-4"
                />
                <div className="text-justify">
                  Unit No. 1115, 11th Floor, Tower - 4,
                  <br />
                  ABC Park, Sector - 135, Noida,
                  <br />
                  UP - 201304 (India)
                </div>
              </address>
              <a
                href="mailto:info@orrizonte.in"
                className="block hover:underline flex leading-relaxed"
              >
                <img
                  src="/assets/icons/mail-icon.svg"
                  alt="Orrizonte"
                  className="me-4"
                />
                info@orrizonte.in
              </a>
              <a
                href="tel:+918527241818"
                className="block hover:underline flex mt-2"
              >
                <img
                  src="/assets/icons/phone-icon.svg"
                  alt="Orrizonte"
                  className="me-4"
                />
                91-8527241818
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

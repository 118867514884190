import './App.scss';
import { Navigate, Route, Routes, useLocation  } from "react-router-dom";
import Home from './app/screens/Home/Home';
import TermsAndConditions from './app/screens/Terms-And-Conditions/TermsAndConditions';
import Header from './app/modules/shared/components/Header/Header';
import Footer from './app/modules/shared/components/Footer/Footer';
import "./index.scss"
import WhoAreWe from './app/screens/Home/components/WhoAreWe/WhoAreWe';
import PrivacyPolicy from './app/screens/Privacy-Policy/PrivacyPolicy';


function App() {

  const location = useLocation();
  const isHomePage = location.pathname === '/home';

  return (
    <div className="App">

      {!isHomePage && <Header />}
      <Routes>
        <Route
            path="/home"
            element={
              <Home/>
            }
          />
      <Route
          path="/terms-and-conditions"
          element={
            <TermsAndConditions/>
          }
        />
      <Route
          path="/privacy-policy"
          element={
            <PrivacyPolicy/>
          }
        />
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
        <Footer />
    </div>
  );
}

export default App;

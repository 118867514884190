import React from "react";
import "./OurServices.scss";
import OtherServices from "../OtherServices/OtherServices";

const OurServices =() =>{
    return(
        <>
        <div className="flex justify-center items-center px-4 lg:px-8 min-h-screen our-services-container">
            <div className="max-w-7xl w-full flex flex-col lg:flex-col items-center lg:items-center p-4 lg:p-16">
                <div className="w-full flex justify-between items-center md:mb-0 flex-col md:flex-row">
                    <h1 className="text-orange-500 text-4xl font-bold">Our Services</h1>
                    <p className="text-gray-600 text-xl leading-loose mt-4 md:mt-0 md:ml-4">
                        Crafting Solutions for Tomorrow's Challenges
                    </p>
                </div>
                <div className="w-full border border-gray-300 mx-auto mt-3"></div>
                <div className="mt-6 px-6">
                    <p>At Orrizonte Technologies, we offer a range of services designed to meet the evolving needs of businesses and communities. Our expertise spans across four core areas:</p>
                </div>

                <div className="w-full mt-5 border rounded-xl shadow-md">
                    <div className="flex flex-col md:flex-row p-5">
                        <div className="w-full md:w-1/2 flex flex-col text-start justify-center px-4 md:px-10 sm:pt-6 md:pt-6 pt-4">
                            <span className="text-xl md:text-2xl font-bold mb-4">1. Software Development</span>
                            <p className="text-base md:text-lg">From custom web and mobile applications to enterprise software solutions, our expert team leverages the latest technologies to bring your ideas to life. Whether you're a startup looking to build a Minimum Viable Product (MVP) or an established enterprise in need of digital transformation, we have the skills and experience to help you succeed.</p>
                        </div>
                        <div className="w-full md:w-1/2 flex justify-center mt-4 md:mt-0">
                            <img
                                src="/assets/images/ourServices/softwareDevelopment.svg" alt="Who-Are-We-Image"
                                className="w-full max-w-md"
                            />
                        </div>
                    </div>

                    <div className="w-11/12 border border-gray-150 mx-auto mt-3"></div>

                    <div className="flex flex-col md:flex-row p-5">
                        <div className="w-full md:w-1/2 flex justify-center mt-4 md:mt-0">
                            <img
                                src="/assets/images/ourServices/edTechSolutions.svg" alt="Who-Are-We-Image"
                                className="w-full max-w-md"
                            />
                        </div>
                        <div className="w-full md:w-1/2 flex flex-col text-start justify-center px-4 md:px-10 sm:pt-6 md:pt-6 pt-4">
                            <span className="text-xl md:text-2xl font-bold mb-4">2. Edtech Solutions</span>
                            <p className="text-base md:text-lg">From custom web and mobile applications to enterprise software solutions, our expert team leverages the latest technologies to bring your ideas to life. Whether you're a startup looking to build a Minimum Viable Product (MVP) or an established enterprise in need of digital transformation, we have the skills and experience to help you succeed.</p>
                        </div>
                        
                    </div>

                    <div className="w-11/12 border border-gray-150 mx-auto mt-3"></div>

                    <div className="flex flex-col md:flex-row p-5">
                        <div className="w-full md:w-1/2 flex flex-col text-start justify-center px-4 md:px-10 sm:pt-6 md:pt-6 pt-4">
                            <span className="text-xl md:text-2xl font-bold mb-4">3. IoT Energy Solutions</span>
                            <p className="text-base md:text-lg">From custom web and mobile applications to enterprise software solutions, our expert team leverages the latest technologies to bring your ideas to life. Whether you're a startup looking to build a Minimum Viable Product (MVP) or an established enterprise in need of digital transformation, we have the skills and experience to help you succeed.</p>
                        </div>
                        <div className="w-full md:w-1/2 flex justify-center mt-4 md:mt-0">
                            <img
                                src="/assets/images/ourServices/iotEnergySolutions.svg" alt="Who-Are-We-Image"
                                className="w-full max-w-md"
                            />
                        </div>
                    </div>

                    <div className="w-11/12 border border-gray-150 mx-auto mt-3"></div>

                    <div className="flex flex-col md:flex-row p-5">
                        <div className="w-full md:w-1/2 flex justify-center mt-4 md:mt-0">
                            <img
                                src="/assets/images/ourServices/educationProgram.svg" alt="Who-Are-We-Image"
                                className="w-full max-w-md"
                            />
                        </div>
                        <div className="w-full md:w-1/2 flex flex-col text-start justify-center px-4 md:px-10 sm:pt-6 md:pt-6 pt-4">
                            <span className="text-xl md:text-2xl font-bold mb-4">4. Education Programs</span>
                            <p className="text-base md:text-lg">From custom web and mobile applications to enterprise software solutions, our expert team leverages the latest technologies to bring your ideas to life. Whether you're a startup looking to build a Minimum Viable Product (MVP) or an established enterprise in need of digital transformation, we have the skills and experience to help you succeed.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>    
    )
}

export default OurServices;